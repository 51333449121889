import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { injectIntl } from "gatsby-plugin-intl";
import { Contentful_Collections, Contentful_ContentTypeCollection } from "graphql-types";
import { ContentTypeColour, ThemeContext } from "../contexts/ThemeContext";
import useTree, { CollectionType } from "../hooks/useTree";
import Layout from "../layouts/layout";
import Filter, { GroupType } from "../components/filter";
import Collection from "../components/collection";
import contentfulImageHelper from "../utils/contentful-image-helper";
import { ReorderFilter } from "../utils/reorder-filter";

function CollectionWithFilters({ contentTypes }: { contentTypes: Contentful_ContentTypeCollection }) {
  const sectorsData = useTree(CollectionType.sectors, null, true, false);
  const researchAreasData = useTree(CollectionType.researchAreas, null, true, false);

  let groups: GroupType[] = [
    {
      groupLabel: "Content Type",
      ref: "contentType", // used for Algolia reference
      items: contentTypes.items,
    },
    {
      groupLabel: "Research Area",
      ref: "researchAreasCollection", // used for Algolia reference
      items: researchAreasData,
    },
    {
      groupLabel: "Sector",
      ref: "sectorsCollection", // used for Algolia reference
      items: sectorsData,
    },
  ];

  return (
    <div className="container mx-auto px-6 py-14">
      <Filter groups={ReorderFilter(groups)}>
        <Collection />
      </Filter>
    </div>
  );
}

const CollectionEntryTemplate = ({ data }: { data: any }) => {
  const collectionsData: Contentful_Collections = get(data, "contentful.collections");
  const contentTypes: Contentful_ContentTypeCollection = get(data, "contentful.contentTypeCollection");

  return (
    <ThemeContext.Provider value={ContentTypeColour.Default}>
      <Layout
        title={collectionsData.title}
        metaDescription={collectionsData.metaDescription}
        metaImageUrl={contentfulImageHelper(collectionsData.shareImage).getShareUrl()}
      >
        <main className="container mx-auto px-6 lg:px-20">
          <div className="pt-32 lg:pt-40 mb-10 lg:mb-14">
            <h1 className="font-medium text-slate-400 leading-none last:mb-0 text-5xl md:text-6xl lg:text-7xl mb-0">
              {collectionsData.title}
            </h1>
          </div>
        </main>

        <CollectionWithFilters contentTypes={contentTypes} />
      </Layout>
    </ThemeContext.Provider>
  );
};

export default injectIntl(CollectionEntryTemplate);

export const collectionQuery = graphql`
  query CollectionById($id: String!, $contentful_locale: String!, $preview: Boolean!) {
    contentful {
      collections(id: $id, locale: $contentful_locale, preview: $preview) {
        metaDescription
        sys {
          id
          publishedVersion
        }
        title
        shareImage {
          url
        }
      }
      contentTypeCollection(order: name_ASC) {
        items {
          sys {
            id
            publishedVersion
          }
          name
          singularName
          childrenCollection {
            items {
              label
              title
              description
              sys {
                id
                publishedVersion
              }
            }
          }
        }
      }
    }
  }
`;
